<template>
  <div>
    <div class="position-relative overflow-hidden bg-light">
      <div class="col-md-8 p-lg-5 mx-auto my-5">
        <h1 class="display-4 font-weight-normal">Análise ao vivo com seu time</h1>
        <p class="lead font-weight-normal">
          Tenha um chat e análise em tempo real
          <!-- <strong>aquele round</strong> que você precisa. -->
        </p>
      </div>
    </div>
    <!-- <div class="container spaced">
      <div class="row">
        <div class="col-md-6 col">
          <img src="https://media.giphy.com/media/SJm0bQHxzFhEHUDqAD/giphy.gif" alt>
        </div>
        <div class="col-md-6 col">
          <h4>Você pode filtrar por</h4>

          <ul>
            <li>Mapa</li>
            <li>Economia do Round</li>
            <li>Lado (terroristas/CTs)</li>
            <li>Time</li>
          </ul>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "FeaturesLive",
  metaInfo() {
    return {
      title: "Analise ao vivo com seu time"
    };
  }
};
</script>

<style lang="sass" scoped>
.container.spaced
  .col
    padding: 40px
  // .col
</style>
